<template>
  <div v-loading="loading" class="cotnent">
    <div class="title">{{ $route.query.name }} - 导出考生答题卡</div>
    <div class="tag">
      <a-icon
        type="exclamation-circle"
        theme="filled"
        style="margin-right: 8px; color: #2474ed"
      />注意：生成试卷的文件包需要花费较长时间，请您耐心等待，选择的班级、科目越多，导出时间越长；请完全下载成功后，再导出下一个文件包
    </div>
    <div class="operate">
      <a-space>
        <div>选择学校</div>
        <a-button
          type="link"
          :disabled="progressNum > -1 && progressNum < 100"
          @click="allCheck('school')"
          >全选</a-button
        >
        <a-button
          type="link"
          :disabled="progressNum > -1 && progressNum < 100"
          @click="cancleAll('school')"
          >取消选择</a-button
        >
      </a-space>
    </div>
    <div class="checkbox">
      <a-checkbox-group
        v-if="schooList.length"
        v-model="checkList"
        :disabled="progressNum > -1 && progressNum < 100"
      >
        <a-checkbox
          v-for="(item, index) in schooList"
          :key="index"
          :value="item.id"
          >{{ item.name }}
        </a-checkbox>
      </a-checkbox-group>
      <no-data v-else message="暂无数据"></no-data>
    </div>
    <div class="operate">
      <a-space>
        <div>选择科目</div>
        <a-button
          type="link"
          :disabled="progressNum > -1 && progressNum < 100"
          @click="allCheck('subject')"
          >全选</a-button
        >
        <a-button
          type="link"
          :disabled="progressNum > -1 && progressNum < 100"
          @click="cancleAll('subject')"
          >取消选择</a-button
        >
      </a-space>
    </div>
    <div class="checkbox">
      <a-checkbox-group
        v-if="subjectList.length"
        v-model="subjectIds"
        :disabled="progressNum > -1 && progressNum < 100"
      >
        <a-checkbox
          v-for="(item, index) in subjectList"
          :key="index"
          :value="item.id"
          >{{ item.name }}
        </a-checkbox>
      </a-checkbox-group>
      <no-data v-else message="暂无数据"></no-data>
    </div>
    <div class="title">文件命名规则</div>
    <div class="radios">
      <a-radio-group
        v-model="studentExamIdInFront"
        :disabled="progressNum > -1 && progressNum < 100"
      >
        <a-radio :value="0"> 姓名-考号 </a-radio>
        <a-radio :value="1"> 考号-姓名 </a-radio>
      </a-radio-group>
    </div>
    <a-button
      v-if="progressNum == -1 || progressNum === 100"
      :disabled="(progressNum > -1 && progressNum < 100) || createDisabled"
      type="primary"
      @click="createExport"
      >{{
        createDisabled
          ? "正在生成"
          : progressNum == 100
          ? "重新生成并导出"
          : "生成并导出"
      }}</a-button
    >

    <div v-if="progressNum > -1" class="progress">
      <a-progress
        stroke-linecap="square"
        stroke-color="#2474ED"
        :percent="progressNum"
      />
      <div v-if="progressNum < 100" class="text">
        数据生成中，请耐心等待，或稍后回到此页面进行导出操作。
      </div>
      <div v-else class="text">
        数据已生成，若导出尚未启动，可点击此处
        <a-button type="link" :loading="btnLoading" @click="downloadFile"
          >手动下载</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  getstatschooloption,
  getstatsubjectoption,
} from "@/core/api/academic/common";
import { exportAnswerSheet } from "@/core/api/academic/union";
export default {
  name: "",
  data() {
    return {
      schooList: [],
      checkList: [],
      subjectList: [],
      subjectIds: [],
      loading: false,
      studentExamIdInFront: 1,
      isSearch: null,
      progressNum: -1,
      currentFlag: false,
      btnLoading: false,
      timer: null,
      createDisabled: false,
      firstTime: false,
    };
  },
  created() {
    this.getSchoolist();
    this.isSearch = 1;
    this.firstTime = true;
  },
  destroyed() {
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    continueGet() {
      if (this.progressNum < 100) {
        this.currentFlag = true;
        if (!this.timer) {
          this.timer = setTimeout(() => {
            this.getProgress();
          }, 1500);
        }
      }
    },
    async getProgress() {
      try {
        const res = await exportAnswerSheet({
          statId: this.$route.query.id,
          schoolIds: this.checkList.join(","),
          subjectIds: this.subjectIds.join(","),
          studentExamIdInFront: this.studentExamIdInFront,
          isSearch: this.isSearch,
        });
        this.isSearch = 1;
        this.progressNum = res.data.data.progress;
        if (res.data.data.progress >= 100) {
          this.clearTimer();
          if (this.currentFlag) {
            const { href } = this.$router.resolve({
              path: "/downFile",
              query: {
                url: res.data.data.url,
                name: res.data.data.name,
                type: 1,
              },
            });
            let timer = setTimeout(() => {
              window.open(href, "_blank");
              clearTimeout(timer);
            }, 0);
          }
          this.createDisabled = false;
        } else if (this.firstTime) {
          this.fistTime = false;
          if (this.progressNum > -1) {
            this.clearTimer();
            this.continueGet();
          }
        } else {
          this.clearTimer();
          this.continueGet();
        }
      } catch {
        this.clearTimer();
        this.createDisabled = false;
        this.btnLoading = false;
      }
    },
    async downloadFile() {
      try {
        this.btnLoading = true;
        const res = await exportAnswerSheet({
          statId: this.$route.query.id,
          schoolIds: this.checkList.join(","),
          subjectIds: this.subjectIds.join(","),
          studentExamIdInFront: this.studentExamIdInFront,
          isSearch: this.isSearch,
        });
        this.btnLoading = false;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      } catch {
        this.btnLoading = false;
      }
    },
    cancleAll(type) {
      if (type === "school") {
        this.checkList = [];
      } else if (type === "subject") {
        this.subjectIds = [];
      }
    },
    allCheck(type) {
      if (type === "school") {
        this.checkList = this.schooList.map((item) => item.id);
      } else if (type === "subject") {
        this.subjectIds = this.subjectList.map((item) => item.id);
      }
    },
    async getSchoolist() {
      this.loading = true;
      try {
        const res = await getstatschooloption({
          id: this.$route.query.id,
        });
        this.schooList = res.data.data;
        this.checkList = this.schooList.map((item) => item.id);
        this.getSubjectList();
      } catch {
        this.loading = false;
      }
    },
    async getSubjectList() {
      try {
        const res = await getstatsubjectoption({ id: this.$route.query.id });
        this.subjectList = res.data.data;
        this.subjectIds = this.subjectList.map((item) => item.id);
        this.getProgress();
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } catch {
        this.loading = false;
      }
    },
    createExport() {
      if (!this.checkList.length) {
        this.$message({
          showClose: true,
          message: "请选择学校",
          type: "warning",
        });
        return false;
      }
      if (!this.subjectIds.length) {
        this.$message({
          showClose: true,
          message: "请选择科目",
          type: "warning",
        });
        return false;
      }
      this.currentFlag = true;
      this.createDisabled = true;
      this.isSearch = 0;
      this.progressNum = -1;
      this.timer = null;
      this.firstTime = false;
      this.getProgress();
    },
  },
};
</script>
<style lang="scss" scoped>
.cotnent {
  width: 100%;
  height: 100%;

  .title {
    color: #0a1119;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 18px;
  }

  .progress {
    width: 60%;
    margin: 18px 0;

    .text {
      color: #0a1119;
      font-size: 14px;
      margin-top: 8px;

      .ant-btn-link {
        padding: 0 !important;
        text-decoration: underline !important;
      }
    }
  }
  .tag {
    background: #f3f8ff;
    font-style: 14px;
    letter-spacing: 1px;
    color: #0a1119;
    box-sizing: border-box;
    padding: 8px 10px;
    width: fit-content;
    margin-bottom: 18px;
  }
  .radios {
    margin-bottom: 24px;
  }
  .operate {
    margin: 18px 0;
    font-weight: 600;
  }

  .checkbox {
    width: 40%;
    margin-bottom: 18px;
  }
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
::v-deep .ant-checkbox-wrapper {
  margin-right: 18px;
  margin-bottom: 18px;
}
</style>
